<template>
  <div class="company-concept-page">
    <CommonSection title="企业理念">
      <div class="concept-list">
        <div
          class="list-item"
          v-for="item in CompanyConcepts"
          :key="item.title"
        >
          <span class="item-title">{{ item.title }}：</span><span>{{ item.detail }}</span>
        </div>
      </div>
      <div class="concept-icon-list">
        <div
          class="list-item"
          v-for="item in CompanyConcepts"
          :key="'icon' + item.title"
        >
          <img class="item-icon" v-lazy="item.icon" />
          <div class="item-text">{{ item.title }}</div>
        </div>
      </div>
    </CommonSection>
  </div>
</template>

<script>
import CommonSection from '../components/CommonSection'
import { description, keywords } from '../data/meta'

export default {
  name: 'CompanyConcept',
  metaInfo: {
    title: '零方科技 | 企业理念',
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords + ',业务定位,发展愿景,核心价值观,企业使命' }
    ]
  },
  components: {
    CommonSection
  },
  data() {
    return {
      CompanyConcepts: Object.freeze([
        {
          icon: require('../assets/img/linian_1.png'),
          title: '业务定位',
          detail: '真正懂零售业务的信息数字化专家'
        },
        {
          icon: require('../assets/img/linian_2.png'),
          title: '发展愿景',
          detail: '成为零售企业最信赖的数智化服务品牌'
        },
        {
          icon: require('../assets/img/linian_3.png'),
          title: '核心价值观',
          detail: '客户为先、务实奋斗、激情活力、开放创新、勇于承担、团结协作'
        },
        {
          icon: require('../assets/img/linian_4.png'),
          title: '企业使命',
          detail: '为客户提供专业的信息数字化解决方案和一体化服务；为员工搭建个人持续发展和实现美好梦想的最佳平台；为行业，推动技术创新与效率提升，助力零售行业升级。'
        }
      ])
    }
  }
}
</script>

<style lang="less" scoped>
.company-concept-page {
  width: 100%;
  .concept-list {
    color: #FFFFFF;
    font-size: 16px;
    .list-item {
      margin-bottom: 16px;
      line-height: 40px;
      .item-title {
        margin-right: 6px;
        font-weight: 600;
      }
    }
  }
  .concept-icon-list {
    width: 100%;
    display: flex;
    align-items: center;
    .list-item {
      width: 128px;
      height: 120px;
      padding: 20px 0;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      .item-icon {
        width: 36px;
        height: 36px;
      }
      .item-text {
        margin-top: 20px;
      }
    }
  }
}
</style>
