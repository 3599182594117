<template>
  <div class="company-introduce-page">
    <CommonSection title="零方简介">
      <p>零方信息科技有限公司，以下简称零方。零方创立于2020年9月，作为信息总部，已服务美宜佳20余年；熟悉并专注于便利店、生鲜、母婴、医药、专卖等业态在内的连锁零售业务，致力于成为“零售企业最信赖的数智化服务品牌”。</p>
      <p>公司总部设在成都，东莞设有分公司；目前已拥有300+专业IT技术人员和IT管理专家，创造的零方智能连锁零售平台，含有招商加盟、会员营销、门店智慧经营、员工便捷协同、智慧供应链与厂商协同、业财一体化、门店经营智能、智慧数据算法应用等系统；每月服务来自20+省市、500+城市、30000+门店的以亿计客户。</p>
      <p>作为真正懂零售业务的信息数字化专家，零方能准确、快速理解和洞察客户需求，为零售行业提供标准产品、定制智能系统以及专业解决方案；一直聚焦连锁零售解决方案创新、不断突破自我与服务边界，关注客户的切身利益，以可落地的方案与贴心服务，促进合作企业盈利攀升、达到可持续发展。</p>
      <p>秉承“客户为先、务实奋斗、激情活力、开放创新、勇于承担、团结协作”的价值观，为零售行业专业定制解决方案，同时也培养了许多零售行业内的精英技术人才；为客户提供专业的信息数字化解决方案和一体化服务；为员工搭建个人持续发展和实现美好梦想的最佳平台；为行业推动技术创新与效率提升，助力零售行业升级。</p>
    </CommonSection>
    <CommonSection title="关于我们">
      <p style="text-indent: 0;">关注我们，及时获取数智化全场景新零售方案的前沿信息</p>
      <div class="platform-list">
        <div
          class="list-item"
          v-for="item in platformData"
          :key="item.name"
        >
          <a :href="item.link" target="_blank">
            <img class="item-icon" v-lazy="item.icon"/>
          </a>
        </div>
      </div>
    </CommonSection>
  </div>
</template>

<script>
import CommonSection from '../components/CommonSection'
import { description, keywords } from '../data/meta'

export default {
  name: 'CompanyIntroduce',
  metaInfo: {
    title: '零方科技 | 零方简介',
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords + ',零方简介' }
    ]
  },
  components: {
    CommonSection
  },
  data() {
    return {
      platformData: Object.freeze([
        {
          name: 'weibo',
          icon: require('../assets/img/a1.png'),
          link: 'https://weibo.com/lingfangkeji'
        },
        {
          name: 'weixin',
          icon: require('../assets/img/a2.png'),
          link: 'https://t.1yb.co/DXEU'
        },
        {
          name: 'zhihu',
          icon: require('../assets/img/a3.png'),
          link: 'https://www.zhihu.com/org/ling-fang-ke-ji'
        },
        {
          name: 'toutiao',
          icon: require('../assets/img/a4.png'),
          link: 'https://www.toutiao.com/c/user/token/MS4wLjABAAAA3GLTNeCTssoXH2KKklHq2uHBDWWppY1muCS8YC0EQNPrBT6wSc3Hf5BfObFWwGHf/?wid=1630305363778'
        },
        {
          name: 'sohu',
          icon: require('../assets/img/a5.png'),
          link: 'https://mp.sohu.com/profile?xpt=YWYwNGI3N2YtYjBkMS00ODNjLTkwN2QtZTBjNDA4MWQ3YjQz&amp;_f=index_pagemp_1&amp;spm=smpc.content.author.1.1630304527174lWQakmA'
        },
        {
          name: 'wangyi',
          icon: require('../assets/img/a6.png'),
          link: 'https://www.163.com/dy/media/T1627976600637.html'
        },
        {
          name: 'baijiahao',
          icon: require('../assets/img/a7.png'),
          link: 'https://baijiahao.baidu.com/u?app_id=1707032305885494'
        },
        {
          name: 'fuwuhao',
          icon: require('../assets/img/a8.png'),
          link: 'https://media.om.qq.com/author?id=MOoNX-GJH3AcOad6LZCBIMkw0'
        }
      ])
    }
  }
}
</script>

<style lang="less" scoped>
.company-introduce-page {
  width: 100%;
  .platform-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .list-item {
      width: 140px;
      height: 90px;
      margin-right: 20px;
      margin-bottom: 20px;
      background-color: #FFFFFF;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      .item-icon {
        width: auto;
        height: 50px;
      }
    }
  }
}
</style>
