<template>
  <div class="page-content company-page">
    <div class="company-page-content">
      <CommonTabView :data="companyTabViewData" :router-type="1"/>
    </div>
  </div>
</template>

<script>
import CommonTabView from '../components/CommonTabView'
import { description, keywords } from '../data/meta'

export default {
  name: 'Company',
  metaInfo: {
    title: '零方科技 | 公司介绍',
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords + ',公司简介，零方简介，企业理念，企业宣传片，联系我们' }
    ]
  },
  components: {
    CommonTabView
  },
  data() {
    return {
      companyTabViewData: Object.freeze([
        {
          title: '零方简介',
          link: '/company/introduce',
          router: 'CompanyIntroduce'
        },
        {
          title: '企业理念',
          link: '/company/concept',
          router: 'CompanyConcept'
        },
        // {
        //   title: '企业宣传片'
        //   // link: '/company/propaganda',
        //   // name: 'CompanyPropaganda'
        // },
        {
          title: '联系我们',
          link: '/company/contact',
          router: 'CompanyContact'
        }
      ])
    }
  }
}
</script>

<style lang="less" scoped>
.company-page {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  background: url("../assets/img/about_bg.jpg") no-repeat fixed;
  background-size: cover;
  .company-page-content {
    width: 100%;
    padding: 80px 0;
    height: calc(100vh - 100px);
    overflow-y: scroll;
  }
}
</style>
