<template>
  <div class="company-contact-page">
    <CommonSection class="contact-info" title="零方信息科技有限公司">
      <div class="info-content">
        <div class="content-groups">
          <div class="info-group">
            <div
              class="group-item group-title"
              :class="{'group-title--active': currentTab === 0}"
            >
              <div class="item-text" @click="switchTab(0)">成都总部</div>
            </div>
            <div class="group-item info-address">
              <div class="item-icon">
                <i class="icon-location"/>
              </div>
              <div class="item-text">中国四川省成都市高新区天府四街300号3号楼B栋4层01号</div>
            </div>
            <div class="group-item info-phone">
              <div class="item-icon">
                <i class="icon-phone"/>
              </div>
              <div class="item-text">028-65771085</div>
            </div>
          </div>
          <div class="info-group">
            <div
              class="group-item group-title"
              :class="{'group-title--active': currentTab === 1}"
            >
              <div class="item-text" @click="switchTab(1)">东莞分公司</div>
            </div>
            <div class="group-item info-address">
              <div class="item-icon">
                <i class="icon-location"/>
              </div>
              <div class="item-text">中国广东省东莞市南城街道莞太路南城段21号2栋401室</div>
            </div>
            <div class="group-item info-phone">
              <div class="item-icon">
                <i class="icon-phone"/>
              </div>
              <div class="item-text">0769-38978079</div>
            </div>
          </div>
        </div>
        <div class="content-maps">
          <div class="tab-area">
            <div class="tab-item" :class="'tab-item--' + currentTab"/>
          </div>
          <div class="option-area">
            <div class="option-item option-prev" @click="switchTab(0)">
              <i class="icon-prev"/>
            </div>
            <div class="option-item option-next" @click="switchTab(1)">
              <i class="icon-next"/>
            </div>
          </div>
          <div class="map-area-wrapper">
            <div class="map-area" :class="'map-area--' + currentTab">
              <iframe src="/location.html?point=0&long=104.067698&lat=30.550401" frameborder="0"/>
              <iframe src="/location.html?point=1&long=113.752463&lat=23.030994" frameborder="0"/>
            </div>
            <div class="map-marker">{{ currentTab === 0 ? '成都总部' : '东莞分公司' }}</div>
          </div>
        </div>
      </div>
      <div class="info-contact">
        <div class="contact-form">
          <div
            class="form-item"
            :class="{
            'form-item--warning': warningMap.name,
            'form-item--error': errorMap.name
          }"
          >
            <input
              type="text"
              placeholder="请输入您的名字（必填）"
              v-model.trim="formData.name"
              @blur="validateField('name')"
            />
            <div class="item-tip" v-if="tipMap.name">{{ tipMap.name }}</div>
          </div>
          <div
            class="form-item"
            :class="{
            'form-item--warning': warningMap.phone,
            'form-item--error': errorMap.phone
          }"
          >
            <input
              type="text"
              placeholder="请输入您的手机号码（必填）"
              v-model.trim="formData.phone"
              @blur="validateField('phone')"
            />
            <div class="item-tip" v-if="tipMap.phone">{{ tipMap.phone }}</div>
          </div>
          <div
            class="form-item"
            :class="{
            'form-item--warning': warningMap.company,
            'form-item--error': errorMap.company
          }"
          >
            <input
              type="text"
              placeholder="请输入您的公司名称（必填）"
              v-model.trim="formData.company"
              @blur="validateField('company')"
            />
            <div class="item-tip" v-if="tipMap.company">{{ tipMap.company }}</div>
          </div>
          <div
            class="form-submit"
            :class="{ 'form-submit--loading': loading }"
            @click.stop="submitContactInfo"
          >
            <i class="icon-loading"/>提交信息
          </div>
        </div>
        <div class="contact-items">
          <div class="group-item info-email" style="margin-left: -12px;">
            <div class="item-icon">
              <i class="icon-email"/>
            </div>
            <div class="item-text">support@lfang123.com</div>
          </div>
          <div class="item-text">
            请填好信息，我们会尽快联系您；<br/>
            从这里开始，链接零售一切机会！
          </div>
        </div>
      </div>
    </CommonSection>
  </div>
</template>

<script>
import axios from 'axios'
import CommonSection from '../components/CommonSection'
import { description, keywords } from '../data/meta'

export default {
  name: 'CompanyContact',
  metaInfo: {
    title: '零方科技 | 联系我们',
    meta: [
      {
        name: 'description',
        content: description
      },
      {
        name: 'keywords',
        content: keywords + ',联系我们'
      }
    ]
  },
  components: {
    CommonSection
  },
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        company: ''
      },
      loading: false,
      warningMap: {
        name: false,
        phone: false,
        company: false
      },
      errorMap: {
        name: false,
        phone: false,
        company: false
      },
      tipMap: {
        name: '',
        phone: '',
        company: ''
      },
      currentTab: 0
    }
  },
  methods: {
    validateForm() {
      this.warningMap = {
        name: false,
        phone: false,
        company: false
      }
      this.errorMap = {
        name: false,
        phone: false,
        company: false
      }
      this.tipMap = {
        name: '',
        phone: '',
        company: ''
      }
      const {
        name,
        phone,
        company
      } = this.formData
      if (!name) {
        return {
          result: false,
          message: '名字不能为空',
          field: 'name',
          type: 'warning'
        }
      }
      if (name.length < 2 || name.length > 50) {
        return {
          result: false,
          message: '请输入正确的名字',
          field: 'name',
          type: 'error'
        }
      }
      this.tipMap.name = ''
      if (!phone) {
        return {
          result: false,
          message: '手机号码不能为空',
          field: 'phone',
          type: 'warning'
        }
      }
      if (!/^(13|14|15|16|17|18|19)\d{9}$/.test(phone)) {
        return {
          result: false,
          message: '请输入正确的手机号码',
          field: 'phone',
          type: 'error'
        }
      }
      this.tipMap.phone = ''
      if (!company) {
        return {
          result: false,
          message: '公司名称不能为空',
          field: 'company',
          type: 'warning'
        }
      }
      if (company.length < 2 || company.length > 100) {
        return {
          result: false,
          message: '请输入正确的公司名称',
          field: 'company',
          type: 'error'
        }
      }
      this.tipMap.company = ''
      return {
        result: true,
        message: '表单校验成功',
        field: 'all',
        type: 'success'
      }
    },
    validateField(field) {
      this.warningMap[field] = false
      this.errorMap[field] = false
      this.tipMap[field] = ''
      const {
        name,
        phone,
        company
      } = this.formData
      let validateResult = {
        result: true,
        message: '表单项校验成功',
        field: 'item',
        type: 'success'
      }
      switch (field) {
        case 'name':
          if (!name) {
            validateResult = {
              result: false,
              message: '名字不能为空',
              field: 'name',
              type: 'warning'
            }
          }
          if (name && (name.length < 2 || name.length > 50)) {
            validateResult = {
              result: false,
              message: '请输入正确的名字',
              field: 'name',
              type: 'error'
            }
          }
          break
        case 'phone':
          if (!phone) {
            validateResult = {
              result: false,
              message: '手机号码不能为空',
              field: 'phone',
              type: 'warning'
            }
            break
          }
          if (phone && !/^(13|14|15|16|17|18|19)\d{9}$/.test(phone)) {
            validateResult = {
              result: false,
              message: '请输入正确的手机号码',
              field: 'phone',
              type: 'error'
            }
          }
          break
        case 'company':
          if (!company) {
            validateResult = {
              result: false,
              message: '公司名称不能为空',
              field: 'company',
              type: 'warning'
            }
            break
          }
          if (company && (company.length < 2 || company.length > 100)) {
            validateResult = {
              result: false,
              message: '请输入正确的公司名称',
              field: 'company',
              type: 'error'
            }
          }
          break
      }
      const {
        result,
        type,
        message
      } = validateResult
      if (!result) {
        // this.$message[type](message)
        this[`${type}Map`][field] = true
        this.tipMap[field] = message
        return
      }
      this[`${type}Map`][field] = false
      this.tipMap[field] = ''
    },
    submitContactInfo() {
      const {
        result,
        message,
        type,
        field
      } = this.validateForm()
      if (!result) {
        // this.$message[type](message)
        this[`${type}Map`][field] = true
        this.tipMap[field] = message
        return
      }
      this.loading = true
      const formData = {
        Name: this.formData.name,
        Mobile: this.formData.phone,
        Company: this.formData.company
      }
      axios.post('/api/Open/SubmitJoinInfo', formData)
        .then(response => {
          response = response.data
          if (response.Code === '0') {
            this.$message.success('提交成功')
            this.formData = {
              name: '',
              phone: '',
              company: ''
            }
            this.loading = false
            return
          }
          this.$message.error(response.Message)
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    switchTab(current) {
      this.currentTab = current
    }
  }
}
</script>
<style lang="less" scoped>
.company-contact-page {
  width: 100%;
  display: flex;
  .map-container {
    flex: none;
    width: 360px;
    height: 560px;
    margin-right: 30px;
    margin-left: 16px;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .contact-info {
    width: 100%;
    .group-item {
      display: flex;
      align-items: center;
      .item-icon {
        margin-right: 8px;
        line-height: 32px;
        align-self: flex-start;
      }
      .item-text {
        line-height: 32px;
      }
    }
    .info-content {
      width: 100%;
      .content-groups {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .info-group {
          width: 348px;
          .group-title {
            margin-bottom: 10px;
            .item-text {
              cursor: pointer;
            }
            &:hover {
              color: #F17D1A;
            }
            &--active {
              color: #F17D1A;
            }
          }
        }
      }
      .content-maps {
        position: relative;
        width: 100%;
        margin: 24px 0 36px 0;
        .tab-area {
          width: 100%;
          height: 2px;
          border-radius: 1px;
          background-color: #FFFFFF;
          margin-bottom: 24px;
          .tab-item {
            width: 50%;
            height: 100%;
            background-color: #F17D1A;
            transform: translateX(0);
            will-change: transform;
            transition: .2s ease-in-out;
            &--0 {
              transform: translateX(0);
            }
            &--1 {
              transform: translateX(100%);
            }
          }
        }
        .option-area {
          width: 100%;
          .option-item {
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% + 16px));
            z-index: 100;
            width: 26px;
            height: 54px;
            background: rgba(0, 0, 0, .25);
            border-radius: 2px;
            color: #FFFFFF;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              background: rgba(0, 0, 0, .4);
            }
          }
          .option-prev {
            left: 10px;
          }
          .option-next {
            right: 10px;
          }
        }
        .map-area-wrapper {
          position: relative;
          width: 100%;
          border-radius: 6px;
          overflow: hidden;
          .map-area {
            display: flex;
            transform: translateX(0);
            will-change: transform;
            transition: .2s ease-in-out;
            &--0 {
              transform: translateX(0);
            }
            &--1 {
              transform: translateX(-100%);
            }
            iframe {
              flex: none;
              width: 100%;
              height: 180px;
              overflow: hidden;
            }
          }
          .map-marker {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 100;
            height: 36px;
            padding: 0 16px;
            background: rgba(241, 125, 26, 0.9);
            border-radius: 6px 0 0 0;
            color: #FFFFFF;
            font-size: 16px;
            text-align: center;
            line-height: 36px;
          }
        }
      }
    }
    .info-contact {
      display: flex;
      align-items: center;
      .contact-form {
        flex: 1;
      }
      .contact-items {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 32px;
      }
    }
  }
  .contact-form {
    font-size: 14px;
    .form-item {
      margin-bottom: 24px;
      input {
        width: 400px;
        height: 40px;
        padding-left: 12px;
        background-color: rgba(185, 185, 185, 0.6);
        border-radius: 4px;
        border: 1px solid transparent;
        color: #FFFFFF;
        transition: all .2s ease-in-out;
        &::placeholder {
          text-align: center;
          color: #FFFFFF;
        }
      }
      .item-tip {
        font-size: 12px;
        line-height: 20px;
      }
    }
    .form-item--warning {
      margin-bottom: 4px;
      input {
        border-color: rgb(230, 162, 60);
        background: rgba(230, 162, 60, 0.2);
      }
      .item-tip {
        color: rgb(230, 162, 60);
      }
    }
    .form-item--error {
      margin-bottom: 4px;
      input {
        border-color: rgb(245, 108, 108);
        background: rgba(245, 108, 108, 0.2);
      }
      .item-tip {
        color: rgb(245, 108, 108);
      }
    }
    .form-submit {
      width: 400px;
      height: 42px;
      background-color: #F17D1A;
      border-radius: 4px;
      line-height: 42px;
      text-align: center;
      color: #FFFFFF;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .2s ease-in-out;
      .icon-loading {
        display: none;
        margin-right: 6px;
      }
      &--loading {
        opacity: .5;
        cursor: not-allowed;
        .icon-loading {
          display: block;
        }
      }
    }
  }
}
</style>
