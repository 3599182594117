<template>
  <div class="common-section">
    <h3 class="section-title">{{ title }}</h3>
    <div class="section-content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonSection',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.common-section {
  margin: 0 16px 40px 16px;
  color: #FFFFFF;
  font-size: 16px;
  &:last-child {
    margin-bottom: 16px;
  }
  .section-title {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .section-content {
    p {
      text-indent: 2em;
      line-height: 36px;
      margin-bottom: 32px;
    }
  }
}
</style>
